import whoWeAreImg from "./whoWeAre.svg";
import whyUsImg from "./whyUs.svg";
import whatWeDo from "./whatWeDo.svg";
const assets = {
  whoWeAreImg: whoWeAreImg,
  whyUsImg: whyUsImg,
  whatWeDo: whatWeDo,
};

export default assets;
